export default {
  authority: process.env.VUE_APP_AUTH_URL,
  // authority: 'https://auth.api.peopleware.io',
  client_id: 'timbradofront',
  redirect_uri: `${window.location.origin}/static/callback.html`,
  response_type: 'id_token token',
  scope: 'openid profile apiCommon apiTimbrado',
  post_logout_redirect_uri: process.env.VUE_APP_LOGOUT_URL,
  silent_redirect_uri: `${window.location.origin}/static/silent-renew.html`,
  automaticSilentRenew: true,
  accessTokenExpiringNotificationTime: 10,
  // filterProtocolClaims: true,
  loadUserInfo: true,
};
