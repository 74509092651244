export default [
  {
    path: '/importData',
    component: () => import('../views/modules/importData/ImportData.vue'),
    name: 'importData',
    meta: {
      requiresClaim: true,
      claim: 'ViewImportData',
    },
  },
  {
    path: '/importData/load/:id',
    component: () => import('../views/modules/importData/LoadData.vue'),
    name: 'loadData',
    meta: {
      requiresClaim: true,
      claim: 'ViewImportData',
    },
  },
  {
    path: '/payrollReceipts',
    component: () => import('../views/modules/payrollReceipts/PayrollReceipts.vue'),
    name: 'payrollReceipts',
    children: [
      {
        path: 'employee/:id',
        component: () => import('../views/modules/employees/Employee.vue'),
        name: 'employeeInReceipts',
        meta: {
          requiresClaim: true,
          claim: 'ViewPayrolls',
        },
      },
      {
        path: 'kardex/:id',
        component: () => import('../views/modules/payrollReceipts/kardex/PayrollReceipt'),
        name: 'kardex',
        meta: {
          requiresClaim: true,
          claim: 'ViewPayrollKardex',
        },
      },
    ],
    meta: {
      requiresClaim: true,
      claim: 'ViewPayrolls',
    },
  },
  {
    path: '/emailBlacklist',
    component: () => import('../views/modules/emailBlacklist/EmailBlacklist.vue'),
    name: 'EmailBlacklist',
  },
  {
    path: '/employeesLookup',
    component: () => import('../views/modules/employees/Employees.vue'),
    name: 'employeesLookup',
    children: [
      {
        path: 'employee/:id',
        component: () => import('../views/modules/employees/Employee.vue'),
        name: 'employee',
        meta: {
          requiresClaim: true,
          claim: 'ViewEmployees',
        },
      },
    ],
    meta: {
      requiresClaim: true,
      claim: 'ViewEmployees',
    },
  },
  {
    path: '/laborOutsourcingsConfig',
    component: () => import('../views/modules/outsourcings/Outsourcings.vue'),
    name: 'outsourcings',
    meta: {
      requiresClaim: true,
      claim: 'ViewOutsourcings',
    },
  },
  {
    path: '/laborOutsourcingsConfig/company',
    component: () => import('../views/modules/outsourcings/OutsourcingsByCompany.vue'),
    name: 'byCompany',
    meta: {
      requiresClaim: true,
      claim: 'ViewOutsourcings',
    },
  },
  {
    path: '/laborOutsourcingsConfig/employee',
    component: () => import('../views/modules/outsourcings/OutsourcingsByEmployee.vue'),
    name: 'byEmployee',
    meta: {
      requiresClaim: true,
      claim: 'ViewOutsourcings',
    },
  },
];
