export default [
  {
    path: '/importCSD',
    component: () => import('../views/catalogs/certificates/Certificates.vue'),
    name: 'certificates',
    meta: {
      requiresClaim: true,
      claim: 'ViewCertificates',
    },
  },
  {
    path: '/companyLookup',
    component: () => import('../views/catalogs/companies/Companies.vue'),
    name: 'companies',
    meta: {
      requiresClaim: true,
      claim: 'ViewCompanies',
    },
  },
  {
    path: '/employeeRegistationLookup',
    component: () => import('../views/catalogs/employeeRegistration/EmployeeRegistrations.vue'),
    name: 'employeeRegistration',
    meta: {
      requiresClaim: true,
      claim: 'ViewEmployerRegistrations',
    },
  },
  {
    path: '/businessUnitLookup',
    component: () => import('../views/catalogs/businessUnits/BusinessUnits.vue'),
    name: 'businessUnits',
    meta: {
      requiresClaim: true,
      claim: 'ViewBusinessUnits',
    },
  },
  {
    path: '/employerCompany',
    component: () => import('../views/catalogs/employerCompany/EmployerCompany.vue'),
    name: 'employerCompany',
    meta: {
      requiresClaim: true,
      claim: 'ViewEmployerCompanies',
    },
  },
  {
    path: '/concepts',
    component: () => import('../views/catalogs/concepts/concepts.vue'),
    name: 'concepts',
  },
];
