/* tslint:disable */
/* eslint-disable no-shadow */
// eslint-disable-next-line import/no-named-as-default
import AuthManager from '../utils/AuthManager';

const namespaced = true;
const state = {
  user: null,
};
const getters = {
  access_token: (state) => (state.user ? state.user.access_token : null),
  allClaims: (state) => state.claims,
};

const actions = {
  setUser({ commit }) {
    return AuthManager.IsLogged().then((user) => {
      commit('userLoaded', user);
      return user;
    });
  },
};

const mutations = {
  userLoaded(state, payload) {
    state.user = payload;
  },
};

// eslint-disable-next-line import/prefer-default-export
export const User = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
