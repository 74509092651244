import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import './veeValidate.js';
import CwCoreComponents from "cw_core_components";
import { abilitiesPlugin } from '@casl/vue';
import ability from './ability';

Vue.use(abilitiesPlugin, ability);
Vue.use(CwCoreComponents);
Vue.config.productionTip = false;

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: 'https://aa04426890e648639439dd1791a3d894@sentry.io/1839486',
    integrations: [new Integrations.Vue({ Vue, attachProps: true })]
  });

  // Sentry.configureScope(async scope => {
  //   scope.setUser({
  //     email: await AuthManager.userManager.getUser(user => user.User)
  //   });
  // });
}

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresClaim)) {
    if (to.meta.requiresClaim && ability.cannot(to.meta.claim, 'STM')) {
      next('/401');
    } else {
      next();
    }
  } else {
    next();
  }
});

app.$root.$on('claimsLoaded', () => {
  if (app.$router.currentRoute.meta.requiresClaim)
    if (!ability.can(app.$router.currentRoute.meta.claim, 'STM')) {
      // console.log('not found pushed')
      router.push('/401');
    }
});
