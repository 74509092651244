/* tslint:disable */
/* eslint-disable no-shadow */

const namespaced = true;
const state = {
  notifications: [],
};
const getters = {
  notifications: (state) => state.notifications,
};

const actions = {
  dropNotification: ({ commit }, id) => {
    commit('DROP_NOTIFICATION', id);
  },
  addNotification: ({ commit }, notification) => {
    commit('ADD_NOTIFICATION', notification);
  },
};

const mutations = {
  DROP_NOTIFICATION: (state, id) => {
    state.notifications.forEach((notification) => {
      if (notification.id === id) {
        const index = state.notifications.indexOf(notification);
        state.notifications.splice(index, 1);
      }
    });
  },
  ADD_NOTIFICATION: (state, { message, type, duration = 5000, title }) => {
    state.notifications.push({ message, type, id: new Date().getTime(), duration, title });
  },
};
export const notifications = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
