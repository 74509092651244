import Vue from 'vue';
import Vuex from 'vuex';
import { User } from './user.module';
import { notifications } from './notifications.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    User,
    notifications,
  },
});
