export default [
  {
    path: '/cfdiSummary',
    component: () => import('../views/modules/CFDISummary/CFDISummary.vue'),
    name: 'cfdiSummary',
    meta: {
      requiresClaim: false,
    },
  },
  {
    path: '/outsourcingsSummary',
    component: () => import('../views/modules/outsourcings/OutsourcingsSummary.vue'),
    name: 'outsourcingsSummary',
    meta: {
      requiresClaim: false,
    },
  },
];
