export default [
  {
    path: '/Integrations',
    component: () => import('../views/configuration/integrations/Integrations.vue'),
    name: 'integrations',
  },
  {
    path: '/Config',
    component: () => import('../views/configuration/config/Config.vue'),
    name: 'config',
    children: [],
  },
  {
    path: '/addRole',
    component: () => import('../views/configuration/config/ConfigAddRole.vue'),
    name: 'configAddRole',
  },
  {
    path: '/viewRole/:id',
    component: () => import('../views/configuration/config/ConfigRoleView.vue'),
    name: 'viewRole',
  },
  // {
  //   path: '/Config/Role/:id',
  //   component: () => import('./../views/configuration/config/Config.vue'),
  //   name: 'configRole'
  // }
];
