import { UserManager, WebStorageStateStore } from 'oidc-client';
import OIDC_CONFIG from '../config/oidc.config';

class AuthManager {
  constructor() {
    this.userManager = new UserManager({
      ...OIDC_CONFIG,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    });
  }

  IsLogged() {
    return this.userManager.getUser().then((user) => {
      if (user) {
        const unixNow = Math.floor(Date.now() / 1000);
        if (unixNow > user.expires_at) {
          this.userManager.signinRedirect();
        }
        return user;
      }
      this.userManager.signinRedirect();
    });
  }

  LogOut() {
    return this.userManager.signoutRedirect();
  }

  SilentRenew() {
    return this.userManager.signinSilentCallback();
  }
}

export default new AuthManager();

export { AuthManager };
