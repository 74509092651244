import { AbilityBuilder } from '@casl/ability';

function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item;
  }

  // eslint-disable-next-line no-underscore-dangle
  return item.__typename;
}

export default AbilityBuilder.define({ subjectName }, (cannot) => {
  cannot('redirect', 'authorize');
});
