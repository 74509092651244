<template>
  <div id="app">
    <ValidationObserver ref="validation">
      <Dashboard module="STM" ref="db">
        <router-view />
        <HelpButton />
      </Dashboard>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      hydrated: false,
    };
  },
  async mounted() {
    await this.$apollo.provider.defaultClient.hydrated();
    this.hydrated = true;
  },
};
</script>
