<template>
  <div class="home">
    <div class="home__dashboard">
      <img src="/assets/dashboard.svg" alt="" class="home__dashboard__image" />
      <div class="home__dashboard__text-action">
        <div class="text-action">
          <p class="home__userName">¡Hola {{ user.profile.FirstName }}!</p>
          <p class="home__welcomeText">
            Estamos felices de que estés aquí. Simplifiquemos el timbrado de tu nómina en un par de
            clics.
          </p>
          <router-link :to="{ name: 'payrollReceipts' }" class="home__custome-callback">
            Consultar recibos de nómina
          </router-link>
        </div>
      </div>
      <div class="home__dashboard__stadistics">
        <span class="stadistics__title">Tus números este mes: </span>
        <Layout>
          <Column size="3">
            <div class="stadistics__item">
              <span class="stadistics__item-number" data-dashboard-stats="employeesInMonth">
                {{ dashboardStats.employeesInMonth }}
              </span>
              <span class="stadistics__item-text"> Colaboradores felices </span>
            </div>
          </Column>
          <Column size="3">
            <div class="stadistics__item">
              <span class="stadistics__item-number" data-dashboard-stats="payrollsInMonth">
                {{ dashboardStats.payrollsInMonth }}
              </span>
              <span class="stadistics__item-text"> Nóminas pagadas </span>
            </div>
          </Column>
          <Column size="3">
            <div class="stadistics__item">
              <span class="stadistics__item-number" data-dashboard-stats="stampedPayrollInMonth">
                {{ dashboardStats.stampedPayrollInMonth }}
              </span>
              <span class="stadistics__item-text"> Nóminas timbradas </span>
            </div>
          </Column>
          <Column size="3">
            <div class="stadistics__item">
              <span class="stadistics__item-number" data-dashboard-stats="cancelledPayrollInMonth">
                {{ dashboardStats.cancelledPayrollInMonth }}
              </span>
              <span class="stadistics__item-text"> Nóminas canceladas </span>
            </div>
          </Column>
        </Layout>
      </div>
      <div class="card_advice">
        <div class="card_advice-img">
          <i class="mdi mdi-information" style="color: #5186ff"></i>
        </div>
        <div>
          <h3>¡Información importante!</h3>
          <p>
            A partir del 1° de julio entró en vigor de forma obligatoria la emisión de CFDI's 4.0
            para nómina. Tu cuenta ha sido migrada de forma automática, pero recuerda que es
            importante que valides la denominación y código postal de tus compañías emisoras y
            nombre y código postal de tus colaboradores para evitar que el SAT rechace tus
            timbrados.
          </p>
          <p>Si tienes alguna duda acércate con nosotros y con gusto podemos orientarte.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Odometer from 'odometer';
import 'odometer/themes/odometer-theme-default.css';
import { mapState } from 'vuex';

export default {
  name: 'home',
  mounted() {
    this.getDashboard();
  },
  computed: {
    ...mapState('User', ['user']),
  },
  data() {
    return {
      statsLoad: false,
      dashboardStats: {
        cancelledPayrollInMonth: 0,
        employeesInMonth: 0,
        payrollsInMonth: 0,
        stampedPayrollInMonth: 0,
      },
    };
  },
  methods: {
    getDashboard() {
      this.getAll('stamp/dashboard', '', 'stamp').then((data) => {
        // this.dashboardStats = data;
        Object.keys(data).forEach((key) => {
          const element = document.querySelector(`[data-dashboard-stats=${key}]`);
          const od = new Odometer({
            el: element,
            value: 0,
            theme: 'default',
            format: '(,ddd)',
          });
          this.$nextTick(() => {
            od.update(data[key]);
          });
        });
      });
    },
  },
};
</script>

<style lang="scss">
.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
  font-family: 'Nunito Sans', sans-serif;
}
.home__dashboard {
  max-width: 1088px;
  margin: 0 auto;
  position: relative;
  margin-top: 80px;
  &__image {
    position: absolute;
    top: -24px;
    right: 0;
  }
}
.home__userName {
  font-size: 24px;
  line-height: 32px;
  color: #333333;
  font-weight: 700;
  margin-bottom: 16px;
  margin-top: 0;
}
.home__welcomeText {
  margin: 0;
  font-weight: 600;
  margin-bottom: 8px;
}

.home__custome-callback {
  color: #5186ff;
  font-weight: 600;
  text-decoration: none;
}

.home__dashboard__stadistics {
  margin-top: 40px;
  margin-bottom: 40px;
}

.stadistics {
  &__title {
    font-size: 18px;
    font-weight: 700;
    color: #333333;
    line-height: 24px;
    margin-bottom: 12px;
    display: block;
  }
  &__item {
    &-number {
      font-size: 40px;
      line-height: 54px;
      font-weight: 700;
      color: #333333;
    }
    &-text {
      font-size: 14px;
      line-height: 19px;
      color: #707070;
    }
    & > span {
      display: block;
    }
  }
}

.card_advice {
  display: flex;
  align-items: center;
  background-color: #dad7d7;
  &-img {
    padding: 20px;
  }
}
</style>
